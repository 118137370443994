import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';

import MENU_ITEMS from 'routes/menu';
import { IS_DEV } from 'consts';
import endpoints from 'consts/endpoints';
import { useCompaniesApi, useUsersApi } from 'hooks/api';

import MenuItem from './MenuItem';
import styles from './SideMenu.module.scss';

const SideMenu = () => {
  const { currentUser } = useUsersApi();
  const { ownCompany } = useCompaniesApi();
  const [isCollapsed, toggleIsCollapsed] = useToggle(true);
  const { activeTab } = useSelector(state => state.states.routes);

  const {
    logoSquare,
    logoExpanded,
  } = useMemo(() => {
    try {
      const parsedPortal = JSON.parse(localStorage.getItem('boPortal'));

      return {
        logoSquare: endpoints.getPortalAssetsUrl(parsedPortal.logoSquareLight),
        logoExpanded: endpoints.getPortalAssetsUrl(parsedPortal.logoExpandedLight),
      };
    } catch (error) {
      return {};
    }
  }, []);
  const activePinnedRoute = useMemo(() => {
    if (activeTab) {
      return `/${activeTab.substring(0, activeTab.indexOf(' '))}`;
    }

    return null;
  }, [activeTab]);
  const filteredMenu = useMemo(() => {
    const userPermissions = currentUser.roles || [];
    const isTenant = !ownCompany.parentCompanyId;

    return MENU_ITEMS.reduce((acc, menuItem) => {
      if (menuItem.subLinks?.length) {
        const subLinksModify = menuItem.subLinks.filter(route => (!route.isForTenantOnly || isTenant)
          && (!route.requiredPermission || userPermissions.includes(route.requiredPermission))
          && (!route.isForDevOnly || IS_DEV));

        if (subLinksModify?.length) {
          acc.push({
            ...menuItem,
            subLinks: [...subLinksModify],
          });
        }
      } else if ((!menuItem.isForTenantOnly || isTenant)
        && (!menuItem.requiredPermission || userPermissions.includes(menuItem.requiredPermission))
        && (!menuItem.isForDevOnly || IS_DEV)) {
        acc.push({ ...menuItem });
      }

      return acc;
    }, []);
  }, [currentUser, ownCompany]);

  return (
    <div className={cx(styles.container, { [styles.container_collapsed]: isCollapsed })}>
      <Link className={styles.logo} to="/" data-testid="menu/logo">
        <img src={isCollapsed ? logoSquare : logoExpanded} alt="" />
      </Link>
      <button
        type="button"
        onClick={toggleIsCollapsed}
        className={styles.toggleButton}
        data-testid="menu/toggle-button"
      >
        <Icon icon={`chevron-${isCollapsed ? 'right' : 'left'}`} size={16} />
      </button>
      {filteredMenu.map(item => (
        <MenuItem
          key={item.name}
          {...item}
          isCollapsed={isCollapsed}
          activePinnedRoute={activePinnedRoute}
        />
      ))}
    </div>
  );
};

export default SideMenu;
